<template>
  <div class="container">
    <h1>WorldEdit Befehle</h1>
    <div class="row mb-3">
      <div class="col-lg-12 text-center table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Befehl</th>
            <th scope="col">User</th>
            <th scope="col">User (Voted)</th>
            <th scope="col">Pro</th>
            <th scope="col">Pro+</th>
            <th scope="col">MVP</th>
            <th scope="col">MVP+</th>
            <th scope="col">Expert</th>
            <th scope="col">Erklärung</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>set</td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>replace</td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>fill</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>drain</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>mask/gmask/gsmask</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>smooth</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>blendball</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>stack</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>setlight</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>removelight</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>move</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>deform</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>expand</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>sel</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>undo</td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>redo</td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>copy</td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>rotate</td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>flip</td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>paste</td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>brush</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>cut</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>sphere</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>cyl</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>pyramid</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>hsphere</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>hcyl</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>setbiome</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>//pos1</td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>//pos2</td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>/1</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>/2</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>cancel</td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>wand</td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          <tr>
            <td>clearhistory</td>
            <td></td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td>x</td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TutorialJumpWorld"
}
</script>

<style scoped>
</style>
